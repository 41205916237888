import React from "react";
import Meta from "./../components/Meta";
import VoucherSection from "./../components/VoucherSection";
import { requireAuth } from "./../util/auth";

function VoucherPage(props) {
  return (
    <>
      <Meta title="Dashboard" />
      <VoucherSection
        title="Dashboard"
        subtitle=""
        strapline=""
        size="md"
        bgColor="bg-white"
      />
    </>
  );
}

export default requireAuth(VoucherPage);
