import React, { useState, useEffect } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import SectionHeader from "./SectionHeader";
import { useAuth } from "../util/auth";
import { IconModal } from "./IconModal";
import CardModal from "./CardModal";
import { useBusiness } from "../util/business";
import BusinessCardGrid from "./BusinessCardGrid";
import DashboardActions from "./DashboardActions";
import FormAlert from "./FormAlert";
import { useForm } from "react-hook-form";
import TextField from "./TextField";
import LoadingIcon from "./LoadingIcon";
import { SaveToApp } from "./SaveToApp";
import { calculateDistance } from "../util/location";

function DashboardSection({ strapline }) {
  // State Definitions
  const [modalContent, setModalContent] = useState(null);
  const [cardModalContent, setCardModalContent] = useState(null);
  const [scanResult, setScanResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formAlert, setFormAlert] = useState(null);
  const [pending, setPending] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [filteredBusinesses, setFilteredBusinesses] = useState([]);
  const [isLocationPresent, setIsLocationPresent] = useState(false);
  const [location, setLocation] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const [businessesWithDistance, setBusinessesWithDistance] = useState([]);
  const [sortedBusinesses, setSortedBusinesses] = useState([]);
  const [userNamePresent, setUserNamePresent] = useState(true);

  const { handleSubmit, register, errors } = useForm();
  const auth = useAuth();
  const { businesses } = useBusiness();

  // Get User Location on Mount with Persistence
  useEffect(() => {
    const savedLocation = localStorage.getItem("userLocation");

    if (savedLocation) {
      setLocation(JSON.parse(savedLocation));
      setIsLocationPresent(true);
      setLoading(false); // Assuming location was previously set, stop loading
      return;
    }

    if (!navigator.geolocation) {
      setError("Geolocation is not supported by your browser");
      setLoading(false);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const userLocation = { latitude, longitude };

        if (!latitude || !longitude) {
          setIsLocationPresent(false);
        } else {
          setIsLocationPresent(true);
          setLocation(userLocation);
          localStorage.setItem("userLocation", JSON.stringify(userLocation));
        }
        setLoading(false);
      },
      (err) => {
        setLocationError("Geolocation not available, businesses sorted alphabetically");
        setIsLocationPresent(false);
        setLoading(false);
      }
    );
  }, []);

  // Calculate Distance and Update Businesses with Distance
  useEffect(() => {
    if (location && businesses.length > 0) {
      const updatedBusinesses = businesses.map((business) => {
        const distance = calculateDistance(
          location.latitude,
          location.longitude,
          business.coordinates.latitude,
          business.coordinates.longitude
        );
        return { ...business, distance };
      });
      setBusinessesWithDistance(updatedBusinesses);
    }
  }, [location, businesses]);

  // Sort Businesses by Distance
  useEffect(() => {
    if (businessesWithDistance.length > 0) {
      setSortedBusinesses(
        [...businessesWithDistance].sort((a, b) => a.distance - b.distance)
      );
    } else if (businesses.length > 0) {
      setSortedBusinesses([...businesses]);
    }
  }, [businessesWithDistance, businesses]);

  // Debounce Search Query
  useEffect(() => {
    const handler = setTimeout(() => setDebouncedQuery(searchQuery), 300);
    return () => clearTimeout(handler);
  }, [searchQuery]);

  // Filter Businesses Based on Search Query
  useEffect(() => {
    if (debouncedQuery.trim() === "") {
      setFilteredBusinesses(sortedBusinesses);
    } else {
      setFilteredBusinesses(
        sortedBusinesses.filter((business) =>
          business.name.toLowerCase().includes(debouncedQuery.toLowerCase())
        )
      );
    }
  }, [debouncedQuery, sortedBusinesses]);

  // Check if User Name is Present
  useEffect(() => {
    setUserNamePresent(!!auth?.user?.name?.trim());
  }, [auth.user]);

  // Update Loading and Error State
  useEffect(() => {
    if (businesses.length > 0 && sortedBusinesses.length > 0) {
      setLoading(false);
      setError(null);
    } else if (businesses.length === 0) {
      setLoading(true);
      setFilteredBusinesses([]);
    }
  }, [businesses, sortedBusinesses]);

  // Handlers
  const handleSearchChange = (event) => setSearchQuery(event.target.value);
  const clearSearchQuery = () => setSearchQuery("");

  const handleOpenIconModal = (content) => {
    setModalContent(content);
    setScanResult(null);
  };

  const handleOpenCardModal = (cardContent) => setCardModalContent(cardContent);
  const handleCloseModal = () => {
    setModalContent(null);
    setCardModalContent(null);
    setScanResult(null);
  };

  const onSubmit = (data) => {
    setPending(true);
    auth
      .updateProfile(data)
      .then(() => setFormAlert(null))
      .catch((error) => {
        setFormAlert({ type: "error", message: error.message });
      })
      .finally(() => setPending(false));
  };

  // Allow User to Refresh Location Manually
  const refreshLocation = () => {
    localStorage.removeItem("userLocation");
    // Reset location states
    setLocation(null);
    setIsLocationPresent(false);
    setLocationError(null);
    setLoading(true);
    // Re-run the location fetching logic
    if (!navigator.geolocation) {
      setError("Geolocation is not supported by your browser");
      setLoading(false);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const userLocation = { latitude, longitude };

        if (!latitude || !longitude) {
          setIsLocationPresent(false);
        } else {
          setIsLocationPresent(true);
          setLocation(userLocation);
          localStorage.setItem("userLocation", JSON.stringify(userLocation));
        }
        setLoading(false);
      },
      (err) => {
        setLocationError("Geolocation not available, businesses sorted alphabetically");
        setIsLocationPresent(false);
        setLoading(false);
      }
    );
  };

  // Render Form if User Name is Not Present
  if (!userNamePresent) {
    return (
      <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center justify-center p-10 sm:p-20">
          <div className="bg-white rounded-lg w-full sm:max-w-md max-w-lg">
            {formAlert && <FormAlert type={formAlert.type} message={formAlert.message} />}
            <label
              htmlFor="name"
              className="block text-lg text-center font-medium text-gray-700 mb-2"
            >
              What is your full name?
            </label>
            <TextField
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              error={errors.name}
              inputRef={register({ required: "Please enter your full name" })}
              className="w-full mb-4"
            />
            <button className="w-full py-3 bg-customBeige-300 text-white rounded-md text-center shadow-md hover:bg-customBeige-400 transition-all flex justify-center items-center">
              {!pending ? "Save" : <LoadingIcon className="w-6" />}
            </button>
          </div>
        </div>
      </form>
    );
  }

  // Main Render
  return (
    <>
      {/* Optional: Button to Refresh Location */}
      {/* <div className="container text-center mt-4">
        <button
          onClick={refreshLocation}
          className="text-blue-500 underline mb-4"
        >
          Refresh Location
        </button>
      </div> */}

      {/* Display Location Error if Any */}
      {locationError && (
        <p className="text-red-500 mb-2 text-center">{locationError}</p>
      )}

      {/* Main Content */}
      <img
        className="w-full h-96 lg:w-96 lg:h-auto object-cover mx-auto"
        src="/CRESCENT_CONVENIENCE.png"
        alt="Crescent Convenience"
      />
      <div className="container text-center">
        <SectionHeader
          title={`Welcome ${auth?.user?.name?.split(" ")[0]} 👋`}
          subtitle="Visit one of the businesses below to claim your exclusive discount"
          strapline={strapline}
        />

        <DashboardActions auth={auth} handleOpenIconModal={handleOpenIconModal} />
        <SaveToApp />

        <div className="flex justify-center items-center mb-8 mt-4">
          <div className="relative w-full max-w-md">
            <FaSearch className="absolute left-3 top-3 text-gray-400" />
            <input
              type="text"
              placeholder="Search for a business"
              className="w-full pl-10 pr-10 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-customBeige-300"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            {searchQuery && (
              <FaTimes
                className="absolute right-3 top-3 text-gray-400 cursor-pointer hover:text-gray-600"
                onClick={clearSearchQuery}
              />
            )}
          </div>
        </div>

        {loading ? (
          <p className="text-gray-500">Loading...</p>
        ) : sortedBusinesses.length > 0 ? (
          <BusinessCardGrid
            businessesArray={filteredBusinesses}
            handleOpenCardModal={handleOpenCardModal}
            loading={loading}
            error={error}
            isLocationSorted={isLocationPresent}
          />
        ) : (
          <p className="text-gray-500 text-lg mt-4">
            Hmm...we couldn’t find anything matching your search.
          </p>
        )}

        {modalContent && (
          <IconModal modalContent={modalContent} handleCloseModal={handleCloseModal} />
        )}
        {cardModalContent && (
          <CardModal cardContent={cardModalContent} handleCloseModal={handleCloseModal} />
        )}
      </div>
    </>
  );
}

export default DashboardSection;
